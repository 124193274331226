import React from "react"
import HeadingM from "../shared/HeadingM"
import ContactOtherBase from "../shared/contact/ContactOther"
import { Target } from "../../utils"

const ContactOther = () => {
  const data = {
    target: "noone" as Target,
    tel: "048-598-4023",
    openingHour:
      "通常 (火〜土)　9:30〜18:30\n​長期休み (春・夏・冬休みなど)　8:30〜17:30",
    lineId: "@lsj0509j",
    addFriendLink: "afyrw4D",
    fax: "048-598-4026",
    email: "noone1@noone.jp",
    instagram: "noone201905",
  }
  return (
    <>
      <HeadingM title="その他" target="noone" />
      <ContactOtherBase {...data} />
    </>
  )
}

export default ContactOther
