import React from "react"
import HeadingM from "../shared/HeadingM"
import ContactFormBase from "../shared/contact/ContactForm"

const ContactForm = () => (
  <>
    <HeadingM title="フォーム" target="noone" />
    <ContactFormBase target="noone" />
  </>
)

export default ContactForm
